.pagado {
  background: #00ab55;
}

.no_trabajo {
  background: rgb(163, 163, 163);
}

.anulado {
  background: #ff4842;
}

.background_turned {
  background: #ffc107;
}

.selected {
  background: #003ee8;
}
